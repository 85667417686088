import React, { useContext, useCallback } from 'react'
import { useTransition, animated } from 'react-spring'
import styled from '@emotion/styled'
import debounce from 'debounce'
import { FiRefreshCcw } from 'react-icons/fi'
import { Context } from '../store'
import Page from '../components/common/Page'
import { nextDay } from '../static/nextDay'

const Games = animated(styled.div`
  display: flex;
  flex-wrap: wrap;
`)

const GameContainer = styled.div`
  margin-right: 15px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  width: 120px;
  text-align: center;
`

const GameImage = styled.img`
  border-radius: 3px;
`

const GameName = styled.div`
  font-weight: bold;
`

const GameVotes = styled.div`
  align-items: center;
  background: #000;
  border-radius: 50%;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  display: flex;
  height: 30px;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
`

const GameAddedBy = styled.div`
  font-size: 12px;
`

function Game({ vote, game }) {
  return (
    <GameContainer onClick={() => vote(game)}>
      <GameImage
        src={game.image}
        style={{ width: 120, height: 120, objectFit: 'cover' }}
        alt=""
      />
      <GameVotes>{game.votes}</GameVotes>
      <GameName>{game.name}</GameName>
      <GameAddedBy>
        Přidal <b>{game.addedBy.name}</b>
      </GameAddedBy>
    </GameContainer>
  )
}

const refreshButton = {
  background: '#000',
  color: '#fff',
  borderRadius: 3,
  padding: '5px 15px',
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  marginBottom: 5
}

const uri =
  process.env.NODE_ENV !== 'development'
    ? 'https://twitch.koci.dev/api/games'
    : 'http://localhost:3000/api/games'

const voteUri =
  process.env.NODE_ENV !== 'development'
    ? 'https://twitch.koci.dev/api/games/update'
    : 'http://localhost:3000/api/games/update'

export default function Votovani() {
  const [{ games, loading, refresh, badges }, dispatch] = useContext(Context)

  const transitions = useTransition(loading.games, null, {
    from: { opacity: 0, position: 'absolute' },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  const getGames = useCallback(async () => {
    if (
      refresh &&
      Math.abs(refresh.getTime() - new Date().getTime()) / 1000 < 30
    ) {
      return
    }
    dispatch({ type: 'SHOW_LOADING', payload: 'games' })
    const api = await fetch(uri, {
      credentials: 'include'
    })
    const data = await api.json()
    dispatch({ type: 'LOAD_GAMES', payload: data })
    dispatch({ type: 'HIDE_LOADING', payload: 'games' })
    dispatch({ type: 'SET_REFRESH', payload: new Date() })
  }, [dispatch, refresh])

  const vote = debounce(async function(game) {
    if (!badges.subscriber) {
      return
    }

    dispatch({
      type: 'SHOW_NOTIFICATION',
      payload: 'Ověřuji data...'
    })

    const subDay = nextDay(new Date(), 0)

    const voteResult = await fetch(voteUri, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: game._id,
        subDay,
        game
      })
    })

    const data = await voteResult.json()

    if (data.voteDupResult) {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: 'Pro nadcházejíci subday už jsi použil všechny svoje voty!'
      })
    } else {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: 'Tvůj vote byl započten.'
      })
      dispatch({ type: 'ADD_VOTE', payload: game._id })
    }

    setTimeout(() => {
      dispatch({ type: 'HIDE_NOTIFICATION' })
    }, 1500)
  }, 200)

  const filteredGames =
    games && games.filter(x => x.state === 1).sort((a, b) => b.votes - a.votes)

  return (
    <Page>
      <h2>Votování</h2>
      {badges && badges.subscriber ? (
        <>
          <div>
            Jsi <b>sub</b> a proto máš možnost votovat o výběru her do dalšího
            Subdaye.
          </div>
          <p>
            Máš <b>1</b> hlas na každý Subday.
          </p>
        </>
      ) : (
        <p>
          Nejsi <b>sub</b> a proto nemůžeš votovat.
        </p>
      )}
      <div style={refreshButton} onClick={getGames}>
        Aktualizovat
        <FiRefreshCcw style={{ marginLeft: 5, width: 24, height: 24 }} />
      </div>
      {transitions.map(({ item, key, props }) =>
        item ? (
          <animated.div key={key} style={props}>
            Načítání...
          </animated.div>
        ) : (
          <Games key={key} style={props}>
            {filteredGames.length > 0 ? (
              filteredGames.map(game => (
                <Game key={game._id} game={game} vote={vote} />
              ))
            ) : (
              <h4>Zatím nebyly potvrzeny žádné hry.</h4>
            )}
          </Games>
        )
      )}
    </Page>
  )
}
