import React, { useContext, useEffect, lazy, Suspense } from 'react'
import { Router, navigate } from '@reach/router'
import { Context } from '../store'
import Header from './Header'
import UserBox from './UserBox'
import NavBar from './NavBar'
import Home from '../pages/Home'
import Requesty from '../pages/Requesty'
import Votovani from '../pages/Votovani'
import Notifications from './Notifications'

const Administrace = lazy(() => import('../pages/Administrace'))
const AdminRequesty = lazy(() => import('../pages/admin/Requesty'))
const AdminVotes = lazy(() => import('../pages/admin/Votes'))
const Dump = lazy(() => import('../pages/Dump'))

const uri =
  process.env.NODE_ENV !== 'development'
    ? 'https://twitch.koci.dev/api/full-request'
    : 'http://localhost:3000/api/full-request'

export default function Interface() {
  const [state, dispatch] = useContext(Context)

  useEffect(() => {
    async function fetchUser() {
      const res = await fetch(uri, {
        credentials: 'include'
      })

      const data = await res.json()

      if (data && data.user) {
        dispatch({ type: 'FULL_LOAD', payload: data })
      } else {
        dispatch({ type: 'HIDE_ALL' })
      }
    }

    fetchUser()
  }, [dispatch])

  useEffect(() => {
    if (state.preUri && state.authenticated) {
      navigate(state.preUri)
      dispatch({ type: 'PRE_URI', payload: undefined })
    }
  }, [dispatch, state.authenticated, state.preUri])

  return (
    <>
      <Header>
        <NavBar />
        <UserBox state={state} />
      </Header>
      <Notifications notification={state.notification} />
      <Suspense fallback={<p>Načítání specifikovaných stránek.</p>}>
        <Router primary={false}>
          <Home path="/" />
          <Dump path="/dump" />
          <Administrace path="/admin">
            <AdminVotes path="/votes" />
            <AdminRequesty path="/requesty" />
            <AdminRequesty path="/requesty/:id" />
          </Administrace>
          <Requesty path="/requesty" />
          <Votovani path="/votovani" />
        </Router>
      </Suspense>
    </>
  )
}
