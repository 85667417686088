import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'
import { Context } from '../store'
import NavLink from './common/NavLink'

const Container = animated(styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;

  & a {
    color: #999;
    margin: 0 5px;
    font-weight: 700;
  }
`)

export default function NavBar() {
  const [state] = useContext(Context)
  const props = useSpring({
    opacity: state.user ? 1 : 0
  })

  return (
    <Container style={props}>
      <NavLink to="/">Hlavní stránka</NavLink>
      {state.user && <NavLink to="/votovani">Votování</NavLink>}
      {state.user && state.badges.subscriber && (
        <>
          <NavLink to="/requesty">Requesty</NavLink>
        </>
      )}
      {state.user && state.badges.moderator && (
        <>
          <NavLink to="/dump" style={{ color: '#3bb866' }}>
            Dump
          </NavLink>
          <NavLink to="/admin/requesty" style={{ color: '#3bb866' }}>
            Administrace
          </NavLink>
        </>
      )}
    </Container>
  )
}
