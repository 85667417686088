export default {
  broadcaster1:
    'https://static-cdn.jtvnw.net/badges/v1/5527c58c-fb7d-422d-b71b-f309dcb85cc1/3',
  partner1:
    'https://static-cdn.jtvnw.net/badges/v1/d12a2e27-16f6-41d0-ab77-b780518f00a3/3',
  vip1:
    'https://static-cdn.jtvnw.net/badges/v1/b817aba4-fad8-49e2-b88a-7cc744dfa6ec/3',
  moderator1:
    'https://static-cdn.jtvnw.net/badges/v1/3267646d-33f0-4b17-b3df-f923a41db1d0/3',
  subscriber1:
    'https://static-cdn.jtvnw.net/badges/v1/13442914-1b56-47f4-a3c9-d5503453c284/3',
  subscriber3:
    'https://static-cdn.jtvnw.net/badges/v1/ed45860a-7e04-458f-b7ec-94b833291b8a/3',
  subscriber6:
    'https://static-cdn.jtvnw.net/badges/v1/e1dc2cc1-9cb3-44c6-9b59-344b1774f9a1/3',
  subscriber12:
    'https://static-cdn.jtvnw.net/badges/v1/eab92409-e4c0-471b-b329-f4971fe9774c/3',
  subscriber24:
    'https://static-cdn.jtvnw.net/badges/v1/107d4f03-c985-49ad-8ac7-917c4f4da4b1/3',
  subscriber36:
    'https://static-cdn.jtvnw.net/badges/v1/b9d2741d-a334-4b03-85e1-387a4d5198f3/3'
}
