import React from 'react'
import styled from '@emotion/styled'
import { useSpring, animated } from 'react-spring'

const Container = animated(styled.div`
  top: 64px;
  display: flex;
  font-weight: bold;
  padding: 5px;
  position: fixed;
  right: 10px;
  background: #e7e7e7;
  border-radius: 3px;
`)

export default function Notifications({ notification }) {
  const props = useSpring({
    opacity: notification.show ? 1 : 0
  })

  return <Container style={props}>{notification.text}</Container>
}
