import React, { useContext } from 'react'
import debounce from 'debounce'
import { Context } from '../store'
import useSubCheck from '../useSubCheck'
import Page from '../components/common/Page'
import {
  GameContainer,
  GameImage,
  GameName,
  Games,
  Input,
  Button
} from '../components/common'
import useInput from '../hooks/useInput'

const uri =
  process.env.NODE_ENV !== 'development'
    ? 'https://twitch.koci.dev/api/games/request'
    : 'http://localhost:3000/api/games/request'

function RenderStatus({ request: { state } }) {
  return (
    <div
      style={{
        borderRadius: '50%',
        width: 14,
        height: 14,
        position: 'absolute',
        top: -5,
        right: -5,
        background: state === 0 ? 'orange' : state === 1 ? '#2eec71' : '#cc343d'
      }}
    />
  )
}

function RenderRequest({ request }) {
  return (
    <GameContainer>
      <div style={{ position: 'relative' }}>
        <GameImage size={50} src={request.image} />
        <RenderStatus request={request} />
      </div>
    </GameContainer>
  )
}

export default function Requesty() {
  useSubCheck()

  const [state, dispatch] = useContext(Context)

  const game = useInput('')
  const image = useInput('')
  const download = useInput('')

  const odeslat = debounce(async function() {
    if (!game.value.length > 0 || !image.value.length > 0) {
      return
    }

    const response = await fetch(uri, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        game: game.value,
        image: image.value,
        download: download.value
      })
    })

    const responseData = await response.json()

    game.clear()
    image.clear()
    download.clear()

    if (!responseData.ok) {
      return
    }

    dispatch({
      type: 'ADD_REQUEST',
      payload: {
        id: responseData.id,
        addedBy: {
          id: state.user.id._id,
          name: state.user.display_name
        },
        image: image.value,
        name: game.value,
        download: download.value,
        playedAt: null,
        request: true,
        state: 0,
        votes: 0
      }
    })
  }, 200)

  const filteredRequests = state.games.filter(
    x => x.addedBy.id === state.user.id
  )

  return (
    <Page>
      <h2>Requesty</h2>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h3 style={{ margin: 0 }}>Tvoje requesty</h3>
      </div>
      <Games>
        {filteredRequests.length > 0 ? (
          filteredRequests.map(request => (
            <RenderRequest key={request._id} request={request} />
          ))
        ) : (
          <p>Zatím žádné requesty...</p>
        )}
      </Games>

      <h3 style={{ marginTop: 10 }}>Přidat request</h3>
      <Input
        type="text"
        name="game"
        value={game.value}
        onChange={game.onChange}
        placeholder="Název hry"
      />
      <Input
        type="text"
        name="image"
        value={image.value}
        onChange={image.onChange}
        placeholder="Odkaz k Obrázku"
      />
      <Input
        type="text"
        name="download"
        value={download.value}
        onChange={download.onChange}
        placeholder="Odkaz ke stažení (Steam...)"
      />
      <Button onClick={odeslat}>Odeslat</Button>

      <GameContainer>
        <GameImage src={image.value} alt="" />
        <GameName>{game.value}</GameName>
      </GameContainer>
    </Page>
  )
}
