import React from 'react'
import { Link } from '@reach/router'

export default function NavLink(props) {
  return (
    <Link
      {...props}
      getProps={({ isCurrent }) => {
        return {
          style: {
            color:
              props.style && props.style.color
                ? props.style.color
                : isCurrent
                ? '#fff'
                : '#999'
          }
        }
      }}
    />
  )
}
