import React from 'react'
import styled from '@emotion/styled'
import { useTransition, animated } from 'react-spring'
import { navigate } from '@reach/router'
import { ReactComponent as Twitch } from '../static/twitch.svg'
import Avatar from './common/Avatar'
import badgesStatic from '../static/badges'

const Container = styled.div`
  align-items: center;
  color: #fff;
  display: flex;
  flex-direction: row-reverse;
  margin-left: auto;
  position: relative;
`

const LoginButton = styled.div`
  align-items: center;
  background: #6441a4;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  padding: 4px 10px;
  min-width: 210px;
`

const User = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
`

const Name = styled.div`
  font-weight: bold;
  margin-right: 5px;
`

const Badge = styled.div`
  ${props => `background: url(${props.bg}) no-repeat`};
  background-size: contain;
  height: 24px;
  width: 24px;
  margin-right: 5px;
`

function RenderUser({ badges, user }) {
  function logout() {
    navigate(
      process.env.NODE_ENV !== 'development'
        ? 'https://twitch.koci.dev/api/logout'
        : 'http://localhost:3000/api/logout'
    )
  }

  return (
    <User>
      <Avatar src={user.profile_image_url} alt="" />
      <Name>{user.display_name}</Name>
      {Object.keys(badges).map(badge => (
        <Badge key={badge} bg={badgesStatic[`${badge}${badges[badge]}`]} />
      ))}
      <div
        style={{ minWidth: 100, cursor: 'pointer', fontWeight: 700 }}
        onClick={logout}
      >
        Odhlásit se
      </div>
    </User>
  )
}

const uri =
  process.env.NODE_ENV !== 'development'
    ? 'https://twitch.koci.dev/api/connect/twitch'
    : 'http://localhost:3000/api/connect/twitch'

const goToLogin = () => {
  window.location.href = uri
}

export function RenderLoginButton() {
  return (
    <LoginButton onClick={goToLogin}>
      <Twitch style={{ fill: '#fff', width: 20, height: 20, marginRight: 5 }} />
      Přihlásit se přes Twitch
    </LoginButton>
  )
}

export default function UserBox({ state }) {
  const transitions = useTransition(state.loading.user, null, {
    from: { opacity: 0, position: 'absolute' },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  })

  return (
    <Container>
      {transitions.map(({ item, key, props }) =>
        item ? (
          <animated.div key={key} style={props}>
            Načítání...
          </animated.div>
        ) : (
          <animated.div key={key} style={props}>
            {!state.authenticated ? (
              <RenderLoginButton />
            ) : (
              <RenderUser user={state.user} badges={state.badges} />
            )}
          </animated.div>
        )
      )}
    </Container>
  )
}
