import React, { useReducer, createContext } from 'react'

export const Context = createContext()

const initialState = {
  authenticated: false,
  refresh: undefined,
  preFullLoad: true,
  loading: {
    user: true,
    games: true,
    requesty: true,
    vote: false,
    adminVotes: true
  },
  notification: {
    show: false,
    text: ''
  },
  tokens: undefined,
  user: undefined,
  votes: [],
  badges: undefined,
  games: [],
  preUri: undefined
}

function reducer(state, action) {
  switch (action.type) {
    case 'FULL_LOAD':
      const badges = action.payload.user.badges.reduce(
        (prev, current) => ({ ...prev, [current.id]: current.version }),
        {}
      )

      return {
        ...state,
        authenticated: action.payload.user ? true : false,
        user: action.payload.user,
        badges,
        loading: {
          ...state.loading,
          user: false,
          games: false,
          requesty: false
        },
        votes: action.payload.votes,
        tokens: action.payload.tokens,
        games: action.payload.games.filter(x => x.addedBy && x.addedBy.name),
        preFullLoad: false
      }

    case 'SHOW_NOTIFICATION':
      return {
        ...state,
        notification: {
          show: true,
          text: action.payload
        }
      }

    case 'HIDE_NOTIFICATION':
      return {
        ...state,
        notification: {
          ...state.notification,
          show: false
        }
      }

    case 'HIDE_ALL':
      return {
        ...state,
        loading: {
          user: false,
          games: false,
          requesty: false,
          vote: false
        }
      }

    case 'ADD_VOTE':
      return {
        ...state,
        games: state.games.map(game => {
          if (game._id === action.payload) {
            game.votes++
          }
          return game
        })
      }

    case 'ADD_REQUEST':
      return {
        ...state,
        games: [...state.games, action.payload]
      }

    case 'SET_REFRESH':
      return {
        ...state,
        refresh: action.payload
      }

    case 'PRE_URI':
      return {
        ...state,
        preUri: action.payload
      }

    case 'LOAD_GAMES':
      return {
        ...state,
        games: action.payload.sort((a, b) => b.votes - a.votes)
      }

    case 'SHOW_LOADING':
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload]: true
        }
      }

    case 'HIDE_LOADING':
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.payload]: false
        }
      }

    case 'CHANGE_STATE':
      return {
        ...state,
        games: state.games.map(game => {
          if (game._id === action.payload.id) {
            game.state = action.payload.to
          }

          return game
        })
      }

    case 'LOAD_ADMIN_VOTES':
      return {
        ...state,
        loading: {
          ...state.loading,
          adminVotes: false
        },
        admin: {
          ...state.admin,
          votes: action.payload
        }
      }

    default:
      return state
  }
}

export default function Provider(props) {
  const value = useReducer(reducer, initialState)
  return <Context.Provider value={value} {...props} />
}
