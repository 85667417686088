import { useEffect, useContext } from 'react'
import { navigate } from '@reach/router'
import { Context } from './store'

export default function useSubCheck(mod) {
  const [state, dispatch] = useContext(Context)

  useEffect(() => {
    if (
      !state.user ||
      (!mod && !state.badges.subscriber) ||
      (mod && !state.badges.moderator)
    ) {
      dispatch({ type: 'PRE_URI', payload: window.location.pathname })
      navigate('/')
    }
  }, [dispatch, mod, state.badges, state.user])

  return true
}
