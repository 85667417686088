import { useState } from 'react'

export default function useInput(initialValue) {
  const [value, setValue] = useState(initialValue)

  function clear() {
    setValue('')
  }

  function onChange(event) {
    setValue(event.target.value)
  }

  return {
    clear,
    onChange,
    value
  }
}
