import React from 'react'
import { render as reactRender } from 'react-dom'
import Interface from './components/Interface'
import Provider from './store'
import './static/reboot.css'

const DOMNode = document.getElementById('root')

function render(NextInterface) {
  reactRender(
    <Provider>
      <NextInterface />
    </Provider>,
    DOMNode
  )
}

render(Interface)

if (module.hot) {
  module.hot.accept('./components/Interface', () => {
    const NextInterface = require('./components/Interface').default
    render(NextInterface)
  })
}
