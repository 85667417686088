import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { Context } from '../store'
import { RenderLoginButton } from '../components/UserBox'
import { nextDay } from '../static/nextDay'

const Center = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  justify-content: center;
  text-align: center;
  width: 100%;
`

function RenderSuperLoggedHome({ state }) {
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
    >
      <h2>Další subday je {nextDay(new Date(), 0)}</h2>
      <h3>Jsi sub a máš odemknuté všechny funkce.</h3>
      <div style={{ fontSize: 10 }}>(A nebo možná nemáš)</div>
      <img
        src="https://cdn.frankerfacez.com/emoticon/333570/4"
        style={{ marginLeft: 10, width: 30, height: 30, objectFit: 'cover' }}
        alt=""
      />
    </div>
  )
}

function RenderLoggedHome({ state }) {
  return !state.badges.subscriber ? (
    <p>
      Bohužel <b>nejsi sub</b> a <b>nemůžeš Votovat/Requestovat</b> hry na další{' '}
      <b>Subday</b>. Pořád se ale můžeš podívat, které byly vybrané, nejvíce
      votované... ostatními.
      <div style={{ marginTop: 20 }} />
      <p>
        Pokud dáš sub, stačí se <b>znovu přihlásit</b> a budou ti{' '}
        <b>umožněny všechny funkce</b>.
      </p>
    </p>
  ) : (
    <RenderSuperLoggedHome state={state} />
  )
}

export default function Home() {
  const [state] = useContext(Context)

  return (
    <Center>
      {state.loading.user ? (
        <div>Načítání...</div>
      ) : !state.authenticated ? (
        <>
          <h2>Ahoj. Vítej na aplikaci pro Mikenovy Subdays.</h2>
          <h3>
            Abys mohl pokračovat, musíš se nejdříve přihlásit pomocí tvého
            Twitch účtu.
          </h3>
          <RenderLoginButton />
        </>
      ) : (
        <RenderLoggedHome state={state} />
      )}
    </Center>
  )
}
