import React from 'react'
import styled from '@emotion/styled'

const Container = styled.div`
  display: flex;
  align-items: center;
  background: #000;
  color: #fff;
  padding: 10px;
  position: relative;

  & .titled {
    font-size: 24px;
    font-weight: bold;
  }

  & .beta {
    font-size: 10px;
    margin-left: 5px;
    text-transform: uppercase;
    padding: 2px 6px;
    background: #cc343d;
    border-radius: 3px;
    font-weight: bold;
  }
`

export default function Header({ children }) {
  return (
    <Container>
      <div className="titled">Miken Subdays</div>
      <div className="beta">beta</div>
      {children}
    </Container>
  )
}
