import styled from '@emotion/styled'

export const Input = styled.input`
  border-radius: 3px;
  border: 1px solid #e7e7e7;
  margin-right: 10px;
  min-width: 240px;
  padding: 4px 10px;
`

export const GameContainer = styled.div`
  align-items: center;
  display: flex;
  margin-top: 10px;
  margin-right: 10px;
`

export const GameName = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
`

export const GameImage = styled.img`
  border-radius: 3px;
  height: ${props => props.size || 80}px;
  object-fit: cover;
  width: ${props => props.size || 80}px;
`

export const Button = styled.button`
  background: #000;
  border-radius: 3px;
  border: 0;
  color: #fff;
  cursor: pointer;
  margin-right: 10px;
  padding: 4px 10px;
`

export const Games = styled.div`
  align-items: center;
  display: flex;
`
